// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-de-jsx": () => import("./../../../src/pages/contact.de.jsx" /* webpackChunkName: "component---src-pages-contact-de-jsx" */),
  "component---src-pages-contact-dk-jsx": () => import("./../../../src/pages/contact.dk.jsx" /* webpackChunkName: "component---src-pages-contact-dk-jsx" */),
  "component---src-pages-galerie-de-js": () => import("./../../../src/pages/galerie.de.js" /* webpackChunkName: "component---src-pages-galerie-de-js" */),
  "component---src-pages-galerie-dk-js": () => import("./../../../src/pages/galerie.dk.js" /* webpackChunkName: "component---src-pages-galerie-dk-js" */),
  "component---src-pages-impressum-de-js": () => import("./../../../src/pages/impressum.de.js" /* webpackChunkName: "component---src-pages-impressum-de-js" */),
  "component---src-pages-impressum-dk-js": () => import("./../../../src/pages/impressum.dk.js" /* webpackChunkName: "component---src-pages-impressum-dk-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-dk-js": () => import("./../../../src/pages/index.dk.js" /* webpackChunkName: "component---src-pages-index-dk-js" */),
  "component---src-pages-speisekarte-de-js": () => import("./../../../src/pages/speisekarte.de.js" /* webpackChunkName: "component---src-pages-speisekarte-de-js" */),
  "component---src-pages-speisekarte-dk-js": () => import("./../../../src/pages/speisekarte.dk.js" /* webpackChunkName: "component---src-pages-speisekarte-dk-js" */)
}

